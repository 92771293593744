import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { AuthApi, useLegacyAccessToken } from '@hcs/auth';
import { hcsConsole } from '@hcs/console';
import { useIsPageVisible, usePrevious } from '@hcs/hooks';
import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';
import {
  useCloseEventSource,
  useCreateEventSource,
  useSubscribeToServerSentEvents,
  useUnsubscribeFromServerSentEvents,
} from '@hcs/sse';
import { useToastSlice } from '@hcs/toast';
import { PortfolioEventTypes, ReportId, SSEEvent } from '@hcs/types';
import { Account, ConnectionStatus, EventSourceType } from '@hcs/types';
import { logException } from '@hcs/utils';

import { PortfolioApi } from '../api';

type PortfolioEventMessageListener = (e: SSEEvent<PortfolioEventTypes>) => void;

class TokenExpiredError extends Error {}

export const PortfolioApiEventsContext = createContext({
  subscribeToServerSentEvents: (params: {
    callbackId: string;
    componentId: string;
    onMessage: PortfolioEventMessageListener;
  }) => {},
  unsubscribeFromServerSentEvents: (params: {
    callbackId: string;
    componentId: string;
  }) => {},
  getConnectionStatus: (): ConnectionStatus => ConnectionStatus.Disconnected,
});

export const PortfolioApiEventsProvider = ({
  portfolioId,
  children,
}: {
  portfolioId: string;
  children: ReactNode;
}) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastClose },
  } = useToastSlice();
  const eventSource = useRef<{
    eventSourceAbortController: AbortController;
    id: string | ReportId;
  } | null>(null);
  const connectionStatus = useRef<ConnectionStatus>(
    ConnectionStatus.Disconnected
  );

  // Subscribed components keep their callbacks in a global
  // mapping so all components can share a single connection
  const listeners = useRef<{
    onMessage: Map<
      string,
      (e: SSEEvent<PortfolioEventTypes>) => void | undefined
    >;
  }>({
    onMessage: new Map(),
  });
  // Keep track of individual components listening to the stream
  const componentsListening = useRef(new Map<string, Map<string, boolean>>());
  const isPageVisible = useIsPageVisible();
  const isPageVisiblePrevious = usePrevious(isPageVisible);
  const { data: accessToken } = useLegacyAccessToken();

  const createEventSource = useCreateEventSource<PortfolioEventTypes>();

  const unsubscribeFromServerSentEvents =
    useUnsubscribeFromServerSentEvents<PortfolioEventTypes>(
      componentsListening,
      listeners
    );

  const subscribeToServerSentEvents =
    useSubscribeToServerSentEvents<PortfolioEventTypes>(
      componentsListening,
      listeners
    );

  const closeEventSource = useCloseEventSource<PortfolioEventTypes>(
    eventSource,
    listeners,
    connectionStatus,
    EventSourceType.Portfolio
  );

  const getConnectionStatus = useCallback(() => {
    return connectionStatus.current;
  }, []);

  useEffect(() => {
    const refreshToken = async () => {
      const account = await AuthApi.fetchAccount();
      queryClient.setQueryData<Account>([ACCOUNT_QUERY_KEY], account);
    };

    // if this is for a new portfolio id
    if (portfolioId !== eventSource.current?.id) {
      closeEventSource({ shouldClearListeners: true });
      createEventSource(
        EventSourceType.Portfolio,
        portfolioId,
        PortfolioApi.createPortfolioApiEventSource,
        eventSource,
        connectionStatus,
        listeners
      ).catch((err) => {
        if (err instanceof TokenExpiredError) {
          // if 401, attempt to refresh token and reconnect
          hcsConsole.logVerbose(
            'Portfolio-Api: SSE |',
            'Retrying Connection after token refresh'
          );
          try {
            // new token should make this useEffect run again
            refreshToken();
          } catch (e) {
            // axios error on token refresh probably means client is offline
            if (axios.isAxiosError(e)) {
              return;
            } else {
              logException(
                new Error(
                  `PortfolioApiEvents.context.tsx: error refreshing token: ${e}`
                )
              );
            }
          } finally {
            closeEventSource({ shouldClearListeners: false });
          }
          return;
        }
        logException(
          new Error(
            `PortfolioApiEvents.context.tsx: catch unhandled error: ${err}`
          )
        );
      });
    }
  }, [
    createEventSource,
    closeEventSource,
    portfolioId,
    accessToken,
    queryClient,
  ]);

  useEffect(() => {
    // if openWhenHidden is false, the connection will silently close and reopen when the tab is hidden/shown
    if (isPageVisiblePrevious && !isPageVisible) {
      connectionStatus.current = ConnectionStatus.Disconnected;
    }
    // if page visible goes from false to true, we will have potentially missed updates that happened when the connection was closed
    // so we should invalidate the portfolio data which will force a refetch of it
    if (isPageVisiblePrevious === false && isPageVisible) {
      // close orphaned "Updating portfolio" toasts
      toastClose();
      connectionStatus.current = ConnectionStatus.Connected;
    }
  }, [
    isPageVisible,
    isPageVisiblePrevious,
    queryClient,
    portfolioId,
    toastClose,
  ]);

  useEffect(() => {
    // if context becomes unmounted, close connection
    return () => {
      // close orphaned "Updating portfolio" toasts
      toastClose();
      closeEventSource({ shouldClearListeners: true });
    };
  }, [closeEventSource, toastClose]);

  return (
    <PortfolioApiEventsContext.Provider
      value={{
        subscribeToServerSentEvents,
        unsubscribeFromServerSentEvents,
        getConnectionStatus,
      }}
    >
      {children}
    </PortfolioApiEventsContext.Provider>
  );
};
