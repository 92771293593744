import { TopLevelBanner } from '@hcs/design-system';
import { useOrgSelfServeInfo } from '@hcs/self-serve-info';
import { formatDate, formatPlanName } from '@hcs/utils';

import styles from './PlanChangedOrCancelledWarning.module.css';
const dataHcName = 'plan-changed-or-cancelled-warning';
export const PlanChangedOrCancelledWarning = ({
  onClickSelectPlan,
}: {
  onClickSelectPlan?: VoidFunction;
}) => {
  const { data: orgSsInfo } = useOrgSelfServeInfo();
  const activeUntil = orgSsInfo?.active_plan?.active_until;
  if (orgSsInfo?.self_serve_status === 'missing-payment-method') {
    return (
      <TopLevelBanner dataHcName="missing-payment-method-warning">
        Please Update Your Payment Method to Continue
        <div className={styles.Line2}>
          The payment method on file was removed from your account. Please
          update your payment method to continue using the platform.
        </div>
      </TopLevelBanner>
    );
  }
  if (orgSsInfo?.self_serve_status === 'payment-failure') {
    return (
      <TopLevelBanner dataHcName="payment-failure-warning">
        Please Update Your Payment Method to Continue
        <div className={styles.Line2}>
          The charge to your payment method on file failed to complete. Please
          update your payment method to continue using the platform.
        </div>
      </TopLevelBanner>
    );
  }
  if (
    (!orgSsInfo?.canceled_subscription && !orgSsInfo?.changed_plan) ||
    // Subscription is ongoing w/o change
    !activeUntil ||
    // No current plan
    !orgSsInfo?.self_serve_plan
  ) {
    return null;
  }
  const currentPlanName = formatPlanName(orgSsInfo.self_serve_plan);
  if (orgSsInfo.changed_plan && orgSsInfo.upcoming_self_serve_plan) {
    return (
      <TopLevelBanner dataHcName={dataHcName} autoHeight>
        Your <b>{currentPlanName} Plan</b> has been changed.
        <div className={styles.Line2}>
          Your <b>{currentPlanName} Plan</b> is scheduled to be changed to the{' '}
          <b>{formatPlanName(orgSsInfo.upcoming_self_serve_plan)} Plan</b>. The
          change will take effect on <b>{formatDate(new Date(activeUntil))}</b>{' '}
          after your current pre-paid subscription ends. Any usage charges
          charges incurred until that date will be billed according to your
          current <b>{currentPlanName} Plan</b> rates.
        </div>
      </TopLevelBanner>
    );
  }
  if (orgSsInfo?.canceled_subscription) {
    return (
      <TopLevelBanner
        autoHeight
        dataHcName={dataHcName}
        actionButton={
          onClickSelectPlan
            ? {
                label: 'Select Plan',
                onClick: onClickSelectPlan,
              }
            : undefined
        }
      >
        Your <b>{currentPlanName} Plan</b> has been Cancelled.
        <div className={styles.Line2}>
          If you would like to continue accessing your HouseCanary account after{' '}
          <b>{formatDate(new Date(activeUntil))}</b> please select a new plan.
        </div>
      </TopLevelBanner>
    );
  }
  return null;
};
