import React, { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { Skeleton } from '@hcs/design-system';
import {
  DialogInputLayout,
  FormFieldsInput,
  FormFieldsSwitch,
} from '@hcs/forms';
import { OrganizationapiCreateOrgRequestInt } from '@hcs/types';

import { OrgTypeDropdown } from '../../features';

import styles from './OrganizationForm.module.css';

const MULTI_INPUT_THEME = { InputContainer: styles.MultiInputLine };
const dataHcName = 'org-form';
interface Props {
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
}
export const OrganizationForm = ({ children, className, isLoading }: Props) => {
  const form = useFormContext<OrganizationapiCreateOrgRequestInt>();
  const { formState } = form;
  const { errors } = formState;
  const inputSkeleton = (
    <Skeleton
      dataHcName={`${dataHcName}-skeleton`}
      type="input"
      className={styles.Input}
    />
  );
  return (
    <form
      data-hc-name={`${dataHcName}-form`}
      className={classNames(styles.Form, className)}
    >
      <DialogInputLayout
        label="Name"
        required
        dataHcName={`${dataHcName}-name`}
      >
        {isLoading ? (
          inputSkeleton
        ) : (
          <FormFieldsInput
            name="name"
            label=""
            error={errors.name?.message?.toString()}
            className={styles.Input}
          />
        )}
      </DialogInputLayout>
      <DialogInputLayout
        label="Org Type"
        dataHcName={`${dataHcName}-type`}
        theme={MULTI_INPUT_THEME}
        required={false}
      >
        {isLoading ? (
          <>
            {inputSkeleton}
            {inputSkeleton}
          </>
        ) : (
          <Controller
            name="type"
            control={form.control}
            render={({ field }) => {
              return (
                <OrgTypeDropdown
                  {...field}
                  className={styles.Input}
                  value={field.value || null}
                  onSelect={(val) => {
                    field.onChange(val);
                    form.clearErrors('type');
                  }}
                  error={errors.type?.message?.toString()}
                />
              );
            }}
          />
        )}
      </DialogInputLayout>
      <DialogInputLayout
        label=""
        dataHcName={`${dataHcName}-salesforce`}
        theme={MULTI_INPUT_THEME}
        required={false}
      >
        <FormFieldsSwitch name="active" label="Active" />
        <FormFieldsSwitch name="test" label="Test Account" />
      </DialogInputLayout>
      {children}
    </form>
  );
};
