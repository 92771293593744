import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { useAccount } from '@hcs/auth';
import {
  EngagementEvent,
  EngagementEventPayload,
  MeaningfulEventTypes,
} from '@hcs/types';
import { capitalizeFirstLetterOfEachWord } from '@hcs/utils';
import { IS_LOCAL } from '@hcs/webapps';

import { isMeaningfulEvent } from '../utils';

import { useBuildEngagementEventData } from './useBuildEngagementEventData';
import { useEngagementTrackingDevToolState } from './useEngagementTrackingDevToolState';
import { useEngagementTrackingMeaningfulEventsSlice } from './useEngagementTrackingMeaningfulEventsSlice';
import { useGlobalEngagementEventFields } from './useGlobalEngagementEventFields';
import { useTrackBeaconEvent } from './useTrackBeaconEvent';
import { useTrackIntercomEvent } from './useTrackIntercomEvent';

export type EngagementEventPayloadWithOptionalFields = {
  app?: EngagementEventPayload['app'];
  httpPath?: EngagementEventPayload['httpPath'];
} & Omit<EngagementEventPayload, 'app' | 'httpPath' | 'httpReferer'>;
declare global {
  interface Window {
    RELEASE: string;
  }
}
// Tracker for calling Beacon and Intercom
export const useTrackEngagementEvent = () => {
  const { isInitialLoading } = useAccount();
  const {
    state: { logEvents },
  } = useEngagementTrackingDevToolState();
  const [queuedEvents, setQueuedEvents] = useState<EngagementEvent[]>([]);
  const { buildEngagementEventData } = useBuildEngagementEventData();
  const {
    state: { eventSection: lastEventSection },
    actions: { setEventSection },
  } = useEngagementTrackingMeaningfulEventsSlice();
  const globalFields = useGlobalEngagementEventFields();
  const trackBeaconEventMutation = useTrackBeaconEvent();
  const trackIntercomMutation = useTrackIntercomEvent();
  const mutation = useMutation(
    async (eventRaw: EngagementEventPayloadWithOptionalFields) => {
      const eventGlobalFields = {
        ...globalFields,
        ...eventRaw,
      };
      const eventData = {
        // Temporarily keep sessionId and subscriptionType in eventData and top-level
        sessionId: eventGlobalFields.sessionId,
        subscriptionType: eventGlobalFields.subscriptionType,
        ...buildEngagementEventData(eventRaw.eventData),
        ...eventRaw.eventData,
        build: window.RELEASE,
      };
      const triggeredEvents: EngagementEventPayload[] = [
        {
          ...eventGlobalFields,
          eventData,
        },
      ];
      if (
        eventData?.eventSection &&
        eventData.eventSection !== lastEventSection
      ) {
        triggeredEvents.push({
          ...eventGlobalFields,
          eventName: `${eventData.eventSection} Section Viewed`,
          eventType: MeaningfulEventTypes.SectionViewed,
          eventData,
        });
      }
      setEventSection({ eventSection: eventData?.eventSection || null });
      if (logEvents) {
        triggeredEvents.forEach((e) => {
          const isMeaningful = isMeaningfulEvent(e);
          if (logEvents === 'all' || isMeaningful) {
            console.log(
              `[Engagement Tracking] ${
                e.eventType
                  ? `${capitalizeFirstLetterOfEachWord(e.eventType)} `
                  : ''
              }Event: ${e.eventName}`,
              e
            );
          }
        });
      }
      if (!IS_LOCAL) {
        if (isInitialLoading) {
          setQueuedEvents([...queuedEvents, ...triggeredEvents]);
        } else {
          triggeredEvents.forEach((e) => {
            trackBeaconEventMutation.mutate(e);
            trackIntercomMutation.mutate(e);
          });
        }
      }
    }
  );
  useEffect(() => {
    if (!isInitialLoading && queuedEvents.length) {
      queuedEvents.forEach((queuedEvent) => mutation.mutate(queuedEvent));
      setQueuedEvents([]);
    }
  }, [isInitialLoading, queuedEvents]);
  return mutation;
};
