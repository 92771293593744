import React from 'react';

import { CardContentTabs, VerticalTab } from '@hcs/design-system';
import {
  AgeIcon,
  BasementIcon,
  BathroomIcon,
  BedroomIcon,
  HomeDarkIcon,
  HomeElectricityIcon,
  LotSizeIcon,
  PoolIcon,
} from '@hcs/design-system';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import {
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import {
  AvmFactor,
  AvmFactorTypes,
  ReportFeatures,
  ReportId,
} from '@hcs/types';
import { ArrayOneOrMore } from '@hcs/types';

import { useAvmBreakdownDocument, useSubjectDocument } from '../../hooks';
import { createAvmFactorTab } from '../../utils/avm-breakdown.utils';

import { AvmBreakdownChart } from './AvmBreakdownValueBarChart';

import styles from './AvmBreakdown.module.css';

const dataHcName = 'avm-breakdown';
export const dataHcEventSectionAvmBreakdown = 'Avm Breakdown';

interface Props {
  reportId: ReportId;
}
export const BAR_ONE_COLOR = '#EDEDED';
export const MAIN_BAR_COLOR = '#6BA0FF';
const NO_POOLS = 'no pools';
const WITH_POOLS = 'with pools';
const NO_BASEMENT = 'no basement';
const WITH_BASEMENT = 'with basement';
const PERCENT_THRESHOLD = 0.5;
export const AVM_BREAKDOWN_FEATURES = [ReportFeatures.AvmBreakdown];
export const AvmBreakdown = ({ reportId }: Props) => {
  const avmBreakdownQuery = useAvmBreakdownDocument(reportId);
  const { data: avmBreakdownData } = avmBreakdownQuery;
  const subjectQuery = useSubjectDocument(reportId);
  const { data: subjectData } = subjectQuery;
  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectData?.data.propertyState,
  };
  const avmFactors = avmBreakdownData?.data?.avmFactors || [];
  const avmFactorsMap = new Map<AvmFactorTypes, AvmFactor>();
  avmFactors.forEach((avmFactor) => {
    avmFactorsMap.set(avmFactor.__typeName, avmFactor);
  });
  const livingAreaAvmFactor = avmFactorsMap.get('LivingAreaAvmFactor');
  const lotSizeAvmFactor = avmFactorsMap.get('LotSizeAvmFactor');
  const bedroomsAvmFactor = avmFactorsMap.get('BedroomsAvmFactor');
  const bathroomsAvmFactor = avmFactorsMap.get('BathroomsAvmFactor');
  const ageAvmFactor = avmFactorsMap.get('AgeAvmFactor');
  const poolAvmFactor = avmFactorsMap.get('PoolAvmFactor');
  const basementAvmFactor = avmFactorsMap.get('BasementAvmFactor');
  const livingArea = getPropertyStateFieldValue(
    PropertyStateFields.livingArea,
    propertyStateArgs
  );
  const lotSize = getPropertyStateFieldValue(
    PropertyStateFields.lotSize,
    propertyStateArgs
  );

  const bathrooms = getPropertyStateFieldValue(
    PropertyStateFields.bathrooms,
    propertyStateArgs
  );

  const bedrooms = getPropertyStateFieldValue(
    PropertyStateFields.bedrooms,
    propertyStateArgs
  );

  const basement = getPropertyStateFieldValue(
    PropertyStateFields.basementHas,
    propertyStateArgs
  );

  const pool = getPropertyStateFieldValue(
    PropertyStateFields.pool,
    propertyStateArgs
  );
  const subjectAge = getPropertyStateFieldValue(
    PropertyStateFields.age,
    propertyStateArgs
  );

  const verticalTabs: ArrayOneOrMore<VerticalTab> = [
    {
      tabId: 'valuationRange',
      tabIconLabel: 'Value',
      tabIcon: (
        <HomeElectricityIcon
          dataHcName={`${dataHcName}-home-data-icon`}
          size="lg"
        />
      ),
      tabLabelTop: 'Valuation Range',
      content: <AvmBreakdownChart reportId={reportId} />,
    },
  ];

  if (
    typeof livingArea === 'number' &&
    livingAreaAvmFactor?.__typeName === 'LivingAreaAvmFactor'
  ) {
    verticalTabs.push(
      createAvmFactorTab(
        dataHcName,
        'home',
        'home',
        livingArea,
        livingAreaAvmFactor,
        'Home',
        HomeDarkIcon,
        'Sq.Ft',
        undefined,
        avmBreakdownData?.data?.tractStats
      )
    );
  }

  if (
    typeof lotSize === 'number' &&
    lotSizeAvmFactor?.__typeName === 'LotSizeAvmFactor'
  ) {
    verticalTabs.push(
      createAvmFactorTab(
        dataHcName,
        'lotSize',
        'lot-size',
        lotSize,
        lotSizeAvmFactor,
        'Lot Size',
        LotSizeIcon,
        'Sq.Ft',
        undefined,
        avmBreakdownData?.data?.tractStats
      )
    );
  }

  if (
    typeof bathrooms === 'number' &&
    bathroomsAvmFactor?.__typeName === 'BathroomsAvmFactor'
  ) {
    verticalTabs.push(
      createAvmFactorTab(
        dataHcName,
        'numBathrooms',
        'bathrooms',
        bathrooms,
        bathroomsAvmFactor,
        'Baths',
        BathroomIcon,
        'Bathrooms',
        undefined,
        avmBreakdownData?.data?.tractStats
      )
    );
  }

  if (
    typeof bedrooms === 'number' &&
    bedroomsAvmFactor?.__typeName === 'BedroomsAvmFactor'
  ) {
    verticalTabs.push(
      createAvmFactorTab(
        dataHcName,
        'numBedrooms',
        'bedrooms',
        bedrooms,
        bedroomsAvmFactor,
        'Beds',
        BedroomIcon,
        'Bedrooms',
        undefined,
        avmBreakdownData?.data?.tractStats
      )
    );
  }

  if (subjectAge && ageAvmFactor?.__typeName === 'AgeAvmFactor') {
    verticalTabs.push(
      createAvmFactorTab(
        dataHcName,
        'age',
        'age',
        subjectAge,
        ageAvmFactor,
        'Age',
        AgeIcon,
        'Years',
        undefined,
        avmBreakdownData?.data?.tractStats
      )
    );
  }

  if (poolAvmFactor?.__typeName === 'PoolAvmFactor') {
    verticalTabs.push(
      createAvmFactorTab(
        dataHcName,
        'hasPool',
        'pools',
        pool ? 1 : 0,
        poolAvmFactor,
        'Pool',
        PoolIcon,
        poolAvmFactor.poolPercent < PERCENT_THRESHOLD ? NO_POOLS : WITH_POOLS,
        !pool ? WITH_POOLS : NO_POOLS,
        avmBreakdownData?.data?.tractStats
      )
    );
  }

  if (poolAvmFactor && basementAvmFactor?.__typeName === 'BasementAvmFactor') {
    verticalTabs.push(
      createAvmFactorTab(
        dataHcName,
        'hasBasement',
        'basement',
        basement ? 1 : 0,
        basementAvmFactor,
        'Basement',
        BasementIcon,
        basementAvmFactor.basementPercent < PERCENT_THRESHOLD
          ? NO_BASEMENT
          : WITH_BASEMENT,
        !basement ? WITH_BASEMENT : NO_BASEMENT,
        avmBreakdownData?.data?.tractStats
      )
    );
  }

  return (
    <CardContentTabs
      searchParamId="avmBreakdown"
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSectionAvmBreakdown}
      active="valuationRange"
      className={styles.AvmBreakdown}
      tabs={verticalTabs}
      theme={{
        Content: styles.Content,
      }}
    />
  );
};
