import { useEffect, useState } from 'react';

import { useAccount } from '@hcs/auth';
import { useOrgSelfServeInfo } from '@hcs/self-serve-info';

import { useTrackEngagementEvent } from '.';

export const useTrackPageViewedEvents = () => {
  const [timeEntered, setTimeEntered] = useState<Date | null>(null);
  const [urlPath, setUrlPath] = useState<string | null>(null);
  const currentUrlPath = window.location.pathname;
  const trackEngagementEventMutation = useTrackEngagementEvent();
  const trackEngagementEvent = trackEngagementEventMutation.mutate;
  const { isFetched: isAuthStateKnown } = useAccount();
  const { isFetched: isSelfServeStateKnown } = useOrgSelfServeInfo();
  useEffect(() => {
    if (timeEntered && isAuthStateKnown && isSelfServeStateKnown) {
      trackEngagementEvent({
        eventName: 'page-view',
        httpPath: window.location.pathname,
        eventData: {
          timeEntered: timeEntered.toUTCString(),
        },
      });
    }
    return () => {
      if (timeEntered && urlPath && isAuthStateKnown && isSelfServeStateKnown) {
        // This will only work when navigating within the platform.
        // Leaving solutions.housecanary.com or closing the browser
        // will not trigger this event
        const timeLeft = new Date();
        trackEngagementEvent({
          eventName: 'page-leave',
          // Need to overwrite because the pathname has changed by the time this event is dispatched
          httpPath: urlPath,
          eventData: {
            timeEntered: timeEntered.toUTCString(),
            timeLeft: timeLeft.toUTCString(),
            secondsOnPage: Math.round(
              (timeLeft.getTime() - timeEntered.getTime()) / 1000
            ),
          },
        });
      }
    };
  }, [
    timeEntered,
    urlPath,
    isAuthStateKnown,
    trackEngagementEvent,
    isSelfServeStateKnown,
  ]);
  useEffect(() => {
    setTimeEntered(new Date());
    setUrlPath(currentUrlPath);
  }, [currentUrlPath]);
};
