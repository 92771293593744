import { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import { Tooltip, TooltipProps } from '../../../display';

import styles from './IconButton.module.css';

interface IconButtonTheme {
  IconButton: string;
  IconButtonText?: string;
}

export interface IconButtonProps {
  dataHcName: string;
  dataHcEventSection?: string;
  icon: ReactNode;
  onClick?: (event?: MouseEvent) => void;
  className?: string;
  disabled?: boolean;
  label?: string;
  horizontal?: boolean;
  theme?: IconButtonTheme;
  stopPropagation?: boolean;
  type?: 'submit' | 'reset' | 'button';
  tooltip?: Omit<TooltipProps, 'dataHcName' | 'trigger'>;
}

export const IconButton = ({
  dataHcName,
  dataHcEventSection,
  onClick,
  className,
  icon,
  horizontal,
  disabled,
  label,
  theme,
  stopPropagation = true,
  type = 'button',
  tooltip,
}: IconButtonProps) => {
  const handleClick = (e: MouseEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (!disabled && onClick) {
      onClick();
    }
  };
  const button = (
    <button
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      onClick={handleClick}
      className={classNames(
        styles.IconButton,
        theme?.IconButton,
        {
          [styles.disabled]: disabled,
          [styles.horizontal]: horizontal,
        },
        className
      )}
      type={type}
    >
      {icon}
      {label && (
        <span
          className={classNames(styles.IconButtonText, theme?.IconButtonText)}
        >
          {label}
        </span>
      )}
    </button>
  );
  if (tooltip) {
    return (
      <Tooltip
        {...tooltip}
        showOnMouseEnter
        dataHcName={`${dataHcName}-tooltip`}
        trigger={button}
      />
    );
  } else {
    return button;
  }
};
