import { useCallback } from 'react';

import { useComponentInternalId } from '@hcs/hooks';
import { EngagementEventData } from '@hcs/types';

import { useEngagementTrackingEventDataSlice } from './useEngagementTrackingEventDataSlice';

// Registers engagement tracking data that remains registered until the browser is refreshed
export const useRegisterAndPeristEngagementTrackingData = () => {
  const componentId = useComponentInternalId();
  const {
    actions: { registerEventData, deregisterEventData },
  } = useEngagementTrackingEventDataSlice();
  const registerAndPersistEventData = useCallback(
    (eventDataId: string, eventData: EngagementEventData | undefined) => {
      if (eventData) {
        registerEventData({
          componentId,
          eventDataId,
          eventData,
        });
      } else {
        deregisterEventData({
          componentId,
          eventDataId,
        });
      }
    },
    [componentId, registerEventData, deregisterEventData]
  );
  return {
    registerAndPersistEventData,
  };
};
