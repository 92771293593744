import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { StripePortalRequest, StripeRedirectResponse } from '@hcs/types';

import { SelfServiceApi } from '../api';

type SelfServicePortalOptions = UseMutationOptions<
  StripeRedirectResponse,
  AxiosError<{ status: string }>,
  StripePortalRequest,
  void
>;

export const useSelfServicePortal = (options?: SelfServicePortalOptions) => {
  return useMutation(async (payload: StripePortalRequest) => {
    const response = await SelfServiceApi.portal(payload);
    return response.data;
  }, options);
};
