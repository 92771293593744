import snakecaseKeys from 'snakecase-keys';
import * as yup from 'yup';

import { HcsAdminOrgApiFilterOps, HcsAdminOrgFilterFields } from '@hcs/types';
import {
  HcsAdminRoleApiFilterOps,
  HcsAdminRoleAssignmentApiFilterOps,
  HcsAdminRoleAssignmentFilterFields,
  HcsAdminRoleFilterFields,
} from '@hcs/types';
import {
  HcsAdminApiComponentApiFilterOps,
  HcsAdminApiComponentFilterFields,
} from '@hcs/types';
import { HcsAdminUserApiFilterOps, HcsAdminUserFilterFields } from '@hcs/types';

export const HCS_ADMIN_ORG_FILTER_OPS: Record<
  HcsAdminOrgFilterFields,
  HcsAdminOrgApiFilterOps
> = {
  name: 'ilike',
  slug: 'ilike',
  active: 'eq',
  activeSubscriptions: 'eq',
  zAccountId: 'ilike',
  zAccountNumber: 'ilike',
  m3terAccountCode: 'ilike',
  m3terAccountId: 'ilike',
  enterprise: 'eq',
  stripeCustomerId: 'ilike',
  hasM3ter: 'neq',
  type: 'neq',
  salesforceAccountId: 'ilike',
  salesforceLeadId: 'ilike',
  created_on: 'ilike',
  last_synced_with_m3ter: 'ilike',
};

export const HCS_ADMIN_USER_FILTER_OPS: Record<
  HcsAdminUserFilterFields,
  HcsAdminUserApiFilterOps
> = {
  first_name: 'ilike',
  last_name: 'ilike',
  email: 'ilike',
  api_auth_keys__access_key: 'any',
  stripe_customer_id: 'ilike',
  active: 'eq',
  created_on: 'ilike',
  last_active: 'ilike',
  organizations: 'eq',
};

export const HCS_ADMIN_ROLE_FILTER_OPS: Record<
  HcsAdminRoleFilterFields,
  HcsAdminRoleApiFilterOps
> = snakecaseKeys({
  name: 'ilike',
});

export const HCS_ADMIN_ROLE_ASSIGNMENT_FILTER_OPS: Record<
  HcsAdminRoleAssignmentFilterFields,
  HcsAdminRoleAssignmentApiFilterOps
> = {
  role_id: 'eq',
  first_name: 'ilike',
  last_name: 'ilike',
  email: 'ilike',
  user__last_name: 'ilike',
  user__email: 'ilike',
  user__last_active: 'ilike',
  created_on: 'ilike',
  organization__name: 'ilike',
};

export const HCS_ADMIN_SETTINGS_API_COMPONENTS_FILTER_OPS: Record<
  HcsAdminApiComponentFilterFields,
  HcsAdminApiComponentApiFilterOps
> = snakecaseKeys({
  name: 'ilike',
  deprecatedSince: 'ilike',
  tier: 'ilike',
  uom: 'ilike',
});

export const HCS_ADMIN_PAGE_SIZE_DEFAULT = 50;

const PHONE_NO_REGEX =
  // eslint-disable-next-line security/detect-unsafe-regex
  /(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;

export const USER_INFO_INPUT_FORM_SCHEMA = yup.object({
  // leaving until discussion around req fields is made
  // firstName: yup.string().required(),
  // lastName: yup.string().required(),
  email: yup.string().email('Email must be valid').required(),
  // streetAddress: yup.string().required(),
  // city: yup.string().required(),
  // state: yup.string().required(),
  // zipCode: yup.string().required(),
  phone: yup.string().nullable().matches(PHONE_NO_REGEX, {
    message: 'Phone number must be a valid US phone number',
    excludeEmptyString: true,
  }),
});

export const USER_INFO_PASSWORD_FORM_SCHEMA = yup.object({
  password: yup
    .string()
    .required('Password required')
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: yup
    .string()
    .required('Confirm password required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

export const NEW_USER_INFO_INPUT_FORM_SCHEMA =
  USER_INFO_INPUT_FORM_SCHEMA.concat(USER_INFO_PASSWORD_FORM_SCHEMA);

export const ORG_DETAILS_INPUT_FORM_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  slug: yup.string().required(),
});

export const ROLE_INFO_INPUT_FORM_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
});

export const ADD_RATE_LIMIT_FORM_SCHEMA = yup.object().shape({
  windowLength: yup.string().required(),
  noOfRequests: yup.number().required(),
  endpointName: yup.string().required(),
  name: yup.string(),
  limitByRequest: yup.boolean().required(),
});
