import { MutableRefObject, useCallback } from 'react';

import { SSEEvent, SSEEventTypes } from '@hcs/types';

export const useUnsubscribeFromServerSentEvents = <T extends SSEEventTypes>(
  componentsListening: MutableRefObject<Map<string, Map<string, boolean>>>,
  listeners: MutableRefObject<{
    onMessage: Map<string, (e: SSEEvent<T>) => void | undefined>;
  }>
) => {
  return useCallback(
    ({
      callbackId,
      componentId,
    }: {
      callbackId: string;
      componentId: string;
    }) => {
      const componentsListeningToCallbackId =
        componentsListening.current.get(callbackId);
      componentsListeningToCallbackId?.delete(componentId);
      if (!componentsListeningToCallbackId?.size) {
        listeners.current.onMessage.delete(callbackId);
        componentsListening.current.delete(callbackId);
      }
    },
    [componentsListening, listeners]
  );
};
