import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isAxiosError } from 'axios';
import * as yup from 'yup';

import { ACCOUNT_FIELD_CONFIGS, AccountFieldInput } from '@hcs/auth';
import {
  ActionButtons,
  Anchor,
  ButtonProps,
  Card,
  Checkbox,
  FormError,
} from '@hcs/design-system';
import {
  AccountFields,
  MeaningfulEventTypes,
  SelfServePlan,
  SelfServiceRegisterFormFields,
} from '@hcs/types';
import { TERMS_OF_USE_URL } from '@hcs/urls';

import {
  SelfServiceRegisterVariables,
  useSelfServiceRegister,
} from '../../hooks';

import styles from './SignUp.module.css';

const yupSchema = yup.object().shape({
  [AccountFields.FirstName]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.FirstName].validator,
  [AccountFields.LastName]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.LastName].validator,
  [AccountFields.CompanyName]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.CompanyName].validator,
  [AccountFields.Email]: ACCOUNT_FIELD_CONFIGS[AccountFields.Email].validator,
  [AccountFields.Password]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.Password].validator,
  [AccountFields.AcceptedTerms]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.AcceptedTerms].validator,
});

export interface SignUpProps {
  className?: string;
  cta?: Omit<ButtonProps, 'dataHcName'>;
  navigateToLogin: VoidFunction;
  onSuccess?: VoidFunction;
  actionPortalIdRender?: string;
  confirmUrl?: string;
  plan: SelfServePlan;
}

const dataHcName = 'self-service-sign-up';

export const SignUpForm = ({
  className,
  navigateToLogin,
  actionPortalIdRender,
  cta,
  onSuccess,
  plan,
  confirmUrl,
}: SignUpProps) => {
  const form = useForm<SelfServiceRegisterFormFields>({
    resolver: yupResolver(yupSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { mutate: selfServiceMutate, isLoading } = useSelfServiceRegister({
    onError(error) {
      if (isAxiosError(error)) {
        // 400 is usually password not meeting requirements or something that isn't a problem to address
        const errors = error.response?.data.errors;
        if (error.response?.status === 400 && errors) {
          if (errors?.first_name) {
            form.setError('firstName', {
              type: 'manual',
              message: errors.first_name,
            });
          }
          if (errors?.last_name) {
            form.setError('lastName', {
              type: 'manual',
              message: errors.last_name,
            });
          }
          if (errors?.email) {
            form.setError('email', {
              type: 'manual',
              message: errors.email,
            });
          }
          if (errors?.password) {
            form.setError('password', {
              type: 'manual',
              message: errors.password,
            });
          }
          if (errors?.accepted_terms) {
            form.setError('acceptedTerms', {
              type: 'manual',
              message: errors.accepted_terms,
            });
          }
          setErrorMessage(
            `Sign Up failed due to invalid fields: ${error.response.data.message}`
          );
          return;
        }
      }
      // ignoring the account api error messages as they are purposefully vague
      setErrorMessage('Sign Up Failed');
    },
    onSuccess() {
      form.reset();
      onSuccess?.();
    },
  });

  const handleSubmit = (data: SelfServiceRegisterFormFields) => {
    const payload: SelfServiceRegisterVariables = {
      first_name: data[AccountFields.FirstName],
      last_name: data[AccountFields.LastName],
      company_name: data[AccountFields.CompanyName] || undefined,
      email: data[AccountFields.Email],
      password: data[AccountFields.Password],
      accepted_terms: data[AccountFields.AcceptedTerms],
      confirm_url: confirmUrl,
      plan,
    };
    selfServiceMutate(payload);
  };
  return (
    <Card
      dataHcEventSection="Self Serve Sign Up Form"
      dataHcName={dataHcName}
      className={className}
    >
      {errorMessage && (
        <FormError
          // Hardcoded to prevent current automation from breaking
          dataHcName={`${dataHcName}-errors`}
          value={<div>{errorMessage}</div>}
        />
      )}
      <FormProvider {...form}>
        <form
          className={styles.Form}
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <div className={styles.SectionLabel}>Your Information</div>
          <Controller
            name={AccountFields.FirstName}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                name={AccountFields.FirstName}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.LastName}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                name={AccountFields.LastName}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.Email}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                onChange={(v) => {
                  setErrorMessage(undefined);
                  field.onChange(v);
                }}
                name={AccountFields.Email}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.Password}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                name={AccountFields.Password}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <div className={styles.SectionLabel}>Company Information</div>
          <Controller
            name={AccountFields.CompanyName}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                className={styles.AccountFieldInput}
                name={AccountFields.CompanyName}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.AcceptedTerms}
            control={form.control}
            render={({ field }) => (
              <div>
                <Checkbox
                  {...field}
                  onChange={(v) => {
                    if (v) {
                      // Specify the window features
                      const features =
                        'width=800,height=800,resizable=yes,scrollbars=yes';
                      window.open(TERMS_OF_USE_URL, '_blank', features);
                    }
                    field.onChange(v);
                  }}
                  dataHcName={`account-field-input-${AccountFields.AcceptedTerms}`}
                  label={
                    <Anchor dataHcName="terms-of-use-link">
                      View and Accept Terms of Use*
                    </Anchor>
                  }
                  checked={field.value || false}
                />
                {!field.value && (
                  <div
                    data-hc-name={`account-field-input-${AccountFields.AcceptedTerms}-error`}
                    className={styles.Error}
                  >
                    Required
                  </div>
                )}
              </div>
            )}
          />
          <div>
            Already Have an Account?{' '}
            <Anchor
              dataHcName={`${dataHcName}-login`}
              onClick={navigateToLogin}
            >
              Login
            </Anchor>
          </div>
          <ActionButtons
            dataHcName={`${dataHcName}-actions`}
            portalIdRender={actionPortalIdRender}
            actions={[
              {
                dataHcName: `${dataHcName}-submit-button`,
                dataHcEventName: 'Self Serve Sign Up Submit',
                dataHcEventType: MeaningfulEventTypes.Goal,
                label: 'Sign Up',
                onClick: () => form.handleSubmit(handleSubmit)(),
                disabled: isLoading || !form.formState.isValid,
                ...(cta || {}),
              },
            ]}
          />
        </form>
      </FormProvider>
    </Card>
  );
};
