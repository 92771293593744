/**
 * Billing codes (uoms) are used to determine
 * which subscriptions a user/org has access to within
 * Order Manager
 */
export enum AgileBillingComponents {
  AgileInsights = 'OM-VR',
  AgileEvaluationInterior = 'OM-VR-INT',
  AgileEvaluationExterior = 'OM-VR-EXT',
  AgileEvaluationExterior2Day = 'OM-AE-2DAY-EXT',
  AgileInspectionInterior = 'OM-INSP-INT',
  AgileInspectionExterior = 'OM-INSP-EXT',
  // Everything in DEV:
  // 'OM-AA-DSKTP',
  // 'OM-AA-EXT',
  // 'OM-AA-INT',
  // 'OM-AE-1DAY-EXT',
  // 'OM-AE-2DAY-EXT',
  // 'OM-AE-CNCL-1DAY-EXT',
  // 'OM-AE-CNCL-2DAY-EXT',
  // 'OM-CNCL-EXT',
  // 'OM-CNCL-INT',
  // 'OM-CNCL-NCG',
  // 'OM-CNCL-VAL',
  // 'OM-INSP-1DAY-EXT',
  // 'OM-INSP-2DAY-EXT',
  // 'OM-INSP-CNCL-1DAY-EXT',
  // 'OM-INSP-CNCL-2DAY-EXT',
  // 'OM-INSP-EXT',
  // 'OM-INSP-INT',
  // 'OM-RUR-INSP',
  // 'OM-RUR-VAL',
  // 'OM-VR',
  // 'OM-VR-EXT',
  // 'OM-VR-INT'
}

export enum PexpBillingComponents {
  ValueReport = 'Value Report',
}

export type DexpBillingComponents =
  | 'block/crime'
  | 'block/hazard_earthquake'
  | 'block/hazard_hail'
  | 'block/hazard_hurricane'
  | 'block/hazard_tornado'
  | 'block/hazard_wind'
  | 'block/hcri'
  | 'block/rental_value_distribution'
  | 'block/superfund'
  | 'block/value_distribution'
  | 'block/value_ts_forecast'
  | 'block/value_ts_historical'
  | 'blockgroup/hcri'
  | 'blockgroup/rental_value_distribution'
  | 'blockgroup/value_distribution'
  | 'blockgroup/value_ts_forecast'
  | 'blockgroup/value_ts_historical'
  | 'metrodiv/affordability_ts_forecast'
  | 'metrodiv/affordability_ts_historical'
  | 'metrodiv/hpi_ts_forecast'
  | 'metrodiv/hpi_ts_historical'
  | 'msa/affordability_ts'
  | 'msa/affordability_ts_forecast'
  | 'msa/affordability_ts_historical'
  | 'msa/details'
  | 'msa/hcri'
  | 'msa/hpi_ts_forecast'
  | 'msa/hpi_ts_historical'
  | 'msa/rpi_ts_forecast'
  | 'msa/rpi_ts_historical'
  | 'property/block_crime'
  | 'property/block_hazard_earthquake'
  | 'property/block_hazard_hail'
  | 'property/block_hazard_hurricane'
  | 'property/block_hazard_tornado'
  | 'property/block_hazard_wind'
  | 'property/block_hcri'
  | 'property/block_rental_value_distribution'
  | 'property/block_superfund'
  | 'property/block_value_distribution'
  | 'property/block_value_ts_forecast'
  | 'property/block_value_ts_historical'
  | 'property/blockgroup_hcri'
  | 'property/blockgroup_rental_value_distribution'
  | 'property/blockgroup_value_distribution'
  | 'property/blockgroup_value_ts_forecast'
  | 'property/blockgroup_value_ts_historical'
  | 'property/census'
  | 'property/details'
  | 'property/details_enhanced'
  | 'property/fema_disaster_area'
  | 'property/flood'
  | 'property/geo_features'
  | 'property/geocode'
  | 'property/land_value'
  | 'property/listing_status'
  | 'property/ltv_details'
  | 'property/ltv_origination'
  | 'property/metrodiv_affordability_ts_forecast'
  | 'property/metrodiv_affordability_ts_historical'
  | 'property/metrodiv_hpi_ts_forecast'
  | 'property/metrodiv_hpi_ts_historical'
  | 'property/mortgage_lien'
  | 'property/mortgage_lien_all'
  | 'property/msa_affordability_ts_forecast'
  | 'property/msa_affordability_ts_historical'
  | 'property/msa_details'
  | 'property/msa_hcri'
  | 'property/msa_hpi_ts_forecast'
  | 'property/msa_hpi_ts_historical'
  | 'property/msa_rpi_ts_forecast'
  | 'property/msa_rpi_ts_historical'
  | 'property/nod'
  | 'property/owner_occupied'
  | 'property/rental_listing_status'
  | 'property/rental_report'
  | 'property/rental_value'
  | 'property/rental_value_forecast'
  | 'property/rental_value_lat_long'
  | 'property/rental_value_within_block'
  | 'property/sales_history'
  | 'property/school'
  | 'property/state_affordability_ts_forecast'
  | 'property/state_affordability_ts_historical'
  | 'property/state_hcri'
  | 'property/state_hpi_ts_forecast'
  | 'property/state_hpi_ts_historical'
  | 'property/state_rpi_ts_forecast'
  | 'property/state_rpi_ts_historical'
  | 'property/tax_history'
  | 'property/value'
  | 'property/value_analysis'
  | 'property/value_by_quality'
  | 'property/value_details_adjusted'
  | 'property/value_forecast'
  | 'property/value_hpi_adjusted'
  | 'property/value_lat_long'
  | 'property/value_report'
  | 'property/value_report_dynamic_link'
  | 'property/value_report_static_link'
  | 'property/value_within_block'
  | 'property/zip_affordability_ts_forecast'
  | 'property/zip_affordability_ts_historical'
  | 'property/zip_details'
  | 'property/zip_hcri'
  | 'property/zip_hpi_forecast'
  | 'property/zip_hpi_historical'
  | 'property/zip_hpi_ts_forecast'
  | 'property/zip_hpi_ts_historical'
  | 'property/zip_market_grade'
  | 'property/zip_rpi_forecast'
  | 'property/zip_rpi_historical'
  | 'property/zip_rpi_ts_forecast'
  | 'property/zip_rpi_ts_historical'
  | 'property/zip_volatility'
  | 'state/affordability_ts_forecast'
  | 'state/affordability_ts_historical'
  | 'state/hcri'
  | 'state/hpi_ts_forecast'
  | 'state/hpi_ts_historical'
  | 'state/rpi_ts_forecast'
  | 'state/rpi_ts_historical'
  | 'zip/affordability_ts_forecast'
  | 'zip/affordability_ts_historical'
  | 'zip/details'
  | 'zip/hcri'
  | 'zip/hpi_forecast'
  | 'zip/hpi_historical'
  | 'zip/hpi_ts_forecast'
  | 'zip/hpi_ts_historical'
  | 'zip/market_grade'
  | 'zip/rpi_forecast'
  | 'zip/rpi_historical'
  | 'zip/rpi_ts_forecast'
  | 'zip/rpi_ts_historical'
  | 'zip/volatility';

export enum PortfolioMonitorBillingComponents {
  AVM = 'PORTFOLIO-MONITOR-AVM',
  Base = 'PORTFOLIO-MONITOR-BASE',
  LTV = 'PORTFOLIO-MONITOR-LTV',
  MLS = 'PORTFOLIO-MONITOR-MLS',
  LIEN = 'PORTFOLIO-MONITOR-LIEN',
  NOD = 'PORTFOLIO-MONITOR-NOD',
}

export type AexpBillingComponents = 'aexp-product-access';
