import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';

import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';
import { useToastSlice } from '@hcs/toast';
import { ConfirmUserRequest, MeaningfulEventTypes } from '@hcs/types';

import { UserApi } from '../api';

type ConfirmUserOptions = UseMutationOptions<
  void,
  AxiosError<{ status: string }>,
  ConfirmUserRequest,
  void
>;

export const useConfirmUser = (options?: ConfirmUserOptions) => {
  const { mutate: trackEngagementEvent } = useTrackEngagementEvent();
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    void,
    AxiosError<{ status: string }>,
    ConfirmUserRequest,
    void
  >(
    async (payload: ConfirmUserRequest) => {
      try {
        // Api returns a 202
        await UserApi.confirmUser(payload);
      } catch (e) {
        if (isAxiosError(e) && e.response?.data.status) {
          const status = e.response.data.status;
          const messageWithCapitalFirstLetter =
            String(status).charAt(0).toUpperCase() + String(status).slice(1);
          throw new Error(messageWithCapitalFirstLetter);
        } else {
          throw e;
        }
      }
    },
    {
      ...options,
      onMutate(variables) {
        toastOpen({
          title: 'Confirming User...',
          type: 'loading',
        });
        options?.onMutate?.(variables);
      },
      onError(error, variables) {
        toastOpen({
          title: 'Failed to Confirm User.',
          type: 'loading-failure',
        });
        options?.onError?.(error, variables);
      },
      onSuccess(data, variables) {
        const { org_id: authOrganizationId, sub: authUserId } = jwtDecode<{
          aud: string[];
          exp: number;
          iat: number;
          iss: string;
          org_id: string; // orgId as string
          sub: string; // userId as string
        }>(variables.token || '');
        trackEngagementEvent({
          eventName: 'confirm-user-complete',
          eventType: MeaningfulEventTypes.Goal,
          authOrganizationId: Number(authOrganizationId),
          authUserId: Number(authUserId),
        });
        toastOpen({
          title: 'Confirmation Successful.',
          type: 'loading-success',
        });
        queryClient.invalidateQueries([ACCOUNT_QUERY_KEY]);
        options?.onSuccess?.(data, variables);
      },
    }
  );
};
