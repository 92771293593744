import { useCallback, useState } from 'react';

import { useWindowResize } from './useWindowResize';

export enum ScreenSizes {
  Desktop = 'desktop',
  Laptop = 'laptop',
  Tablet = 'tablet',
  Phone = 'phone',
}

interface ScreenSizeState {
  height: number | null;
  width: number | null;
  screenSize: ScreenSizes;
  isMobile: boolean;
}

const SCREEN_SIZES: [ScreenSizes, [number | null, number | null]][] = [
  [ScreenSizes.Desktop, [1441, null]],
  [ScreenSizes.Laptop, [1025, 1440]],
  [ScreenSizes.Tablet, [768, 1024]],
  [ScreenSizes.Phone, [null, 767]],
];

const _determineScreenSize = (): ScreenSizeState => {
  const screenSize =
    SCREEN_SIZES.find((screenSize) => {
      const [minWidth, maxWidth] = screenSize[1];
      return (
        (!minWidth || window.innerWidth >= minWidth) &&
        (!maxWidth || window.innerWidth <= maxWidth)
      );
    })?.[0] || ScreenSizes.Desktop;
  return {
    height: window.innerHeight,
    width: window.innerWidth,
    isMobile:
      screenSize === ScreenSizes.Tablet || screenSize === ScreenSizes.Phone,
    screenSize,
  };
};

// A hook to return information about the screen size.
// Useful for cases where we want to render a different component based on window size
// or device type
export const useScreenSize = () => {
  const [screenState, setScreenState] = useState<ScreenSizeState>(
    _determineScreenSize()
  );
  const handleResize = useCallback(() => {
    setScreenState(_determineScreenSize());
  }, []);
  useWindowResize(handleResize);
  return screenState;
};
