import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ActionButtons } from '@hcs/design-system';
import { UpdateOrgParams } from '@hcs/types';

import { OrganizationForm } from '../../components/OrganizationForm/OrganizationForm';
import { useOrganization } from '../../hooks';
import { useEditOrganization } from '../../hooks/useEditOrganization';

export const FORM_SCHEMA = yup.object().shape({
  name: yup.string().required(),
});

interface Props {
  orgId: number;
  actionButtonPortalId?: string;
  onSubmit?: VoidFunction;
  onCancel?: VoidFunction;
}
const dataHcName = 'org-details-edit';
export const EditOrganizationForm = ({
  orgId,
  actionButtonPortalId,
  onSubmit,
  onCancel,
}: Props) => {
  const { data: orgData, isInitialLoading } = useOrganization(orgId);
  const updateOrgDetails = useEditOrganization();
  const form = useForm<UpdateOrgParams>({
    resolver: yupResolver(FORM_SCHEMA),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: useMemo(
      () => ({
        name: orgData?.name,
        active: orgData?.active,
        type: orgData?.type,
        salesforceAccountId: orgData?.salesforceAccountId,
        salesforceLeadId: orgData?.salesforceLeadId,
        test: orgData?.test,
        m3terAccountCode: orgData?.m3terAccountCode,
        m3terAccountId: orgData?.m3terAccountId,
        zAccountId: orgData?.zAccountId,
        zAccountNumber: orgData?.zAccountNumber,
      }),
      [orgData]
    ),
  });

  const { reset } = form;

  useEffect(() => {
    reset({
      name: orgData?.name,
      active: orgData?.active,
      type: orgData?.type,
      salesforceAccountId: orgData?.salesforceAccountId,
      salesforceLeadId: orgData?.salesforceLeadId,
      test: orgData?.test,
      m3terAccountCode: orgData?.m3terAccountCode,
      m3terAccountId: orgData?.m3terAccountId,
      zAccountId: orgData?.zAccountId,
      zAccountNumber: orgData?.zAccountNumber,
    });
  }, [orgData, reset]);

  const handleSubmit = (data: UpdateOrgParams) => {
    onSubmit?.();
    updateOrgDetails.mutate({ ...data, orgId });
  };
  return (
    <FormProvider {...form}>
      <OrganizationForm isLoading={isInitialLoading}>
        <ActionButtons
          portalIdRender={actionButtonPortalId}
          dataHcName={`${dataHcName}-action-buttons`}
          actions={[
            {
              dataHcName: `${dataHcName}-cancel`,
              label: 'Cancel',
              type: 'button',
              onClick: () => {
                onCancel?.();
                form.reset();
              },
              secondary: true,
            },
            {
              dataHcName: `${dataHcName}-save`,
              label: 'Save',
              type: 'submit',
              disabled: !form.formState.isValid || !form.formState.isDirty,
              onClick: form.handleSubmit(handleSubmit),
              primary: true,
            },
          ]}
        />
      </OrganizationForm>
    </FormProvider>
  );
};
