import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';

import { formatMoney, unformat } from '@hcs/utils';

import { DirectionalChevron } from '../../../../foundations/svgs/icons/animated/DirectionalChevron';
import { Anchor } from '../../links';
import { InfoTooltipProps } from '../../popovers/InfoTooltip';

import styles from './Ledger.module.css';

interface LedgerItemDetail {
  arrayKey: string; // some kind of unique id for array
  label: ReactNode;
  value: number | null | undefined;
  columns?: ReactNode[];
}
export interface LedgerItem extends LedgerItemDetail {
  className?: string;
  details?: LedgerItemDetail[];
}

interface ItemDetailsVisibleMap {
  [key: LedgerItem['arrayKey']]: boolean;
}

interface LedgerTotal {
  label: LedgerItemDetail['label'];
  value: LedgerItemDetail['value'];
  editable?: boolean;
  disabled?: boolean;
  onChange?: (v: number | null) => void;
  onBlur?: (v: number | null) => void;
  infoTooltip?: Omit<InfoTooltipProps, 'dataHcName'>;
}
export interface LedgerProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  formatter?: (v: number | null | undefined) => string;
  items: LedgerItem[];
  total: LedgerTotal;
  boldItemLabels?: boolean;
  detailsToggleable?: boolean;
  detailsVisible?: boolean;
}
export const Ledger = ({
  dataHcName,
  className,
  items,
  formatter = formatMoney,
  total,
  detailsToggleable = false,
  detailsVisible = true,
  boldItemLabels = false,
}: LedgerProps) => {
  // can assume items are stable and recalculate visible with initial value
  const [inputValue, setInputValue] = useState(
    total.value == null ? null : formatter(total.value)
  );
  useEffect(() => {
    const propValue = total.value == null ? null : formatter(total.value);
    setInputValue(propValue);
  }, [total.value, formatter]);
  const [detailsVisibleMap, setDetailsVisibleMap] =
    useState<ItemDetailsVisibleMap>({});
  const allDetailsVisible = useMemo(() => {
    return Object.keys(detailsVisibleMap).every(
      (value) => detailsVisibleMap[value]
    );
  }, [detailsVisibleMap]);
  const toggleDetailsVisiblity = useCallback(
    (itemArrayKey: LedgerItem['arrayKey']) => {
      setDetailsVisibleMap((prev) => ({
        ...prev,
        [itemArrayKey]: !prev[itemArrayKey],
      }));
    },
    []
  );
  const toggleAllDetailsVisiblity = useCallback((visible: boolean) => {
    setDetailsVisibleMap((prev) => {
      const newState = { ...prev };
      for (const key in newState) {
        newState[key] = visible;
      }
      return newState;
    });
  }, []);
  useEffect(() => {
    setDetailsVisibleMap(
      items.reduce<ItemDetailsVisibleMap>((acc, item) => {
        if (item.details?.length) {
          acc[item.arrayKey] = detailsVisible;
        }
        return acc;
      }, {})
    );
  }, [items, detailsVisible]);
  const hasItemsWithDetails = Object.keys(detailsVisibleMap).length > 0;
  return (
    <section
      data-hc-name={dataHcName}
      className={classNames(styles.Ledger, className)}
    >
      {detailsToggleable && hasItemsWithDetails && (
        <div className={styles.Controls}>
          <Anchor
            dataHcName={`${dataHcName}-details-toggle`}
            className={styles.DetailsToggle}
            onClick={() => toggleAllDetailsVisiblity(!allDetailsVisible)}
          >
            {allDetailsVisible ? 'Hide All Details' : 'Show All Details'}
          </Anchor>
        </div>
      )}
      <table className={styles.Items}>
        <tbody>
          {items.map((item) => {
            const hasDetails = !!item.details?.length;
            const isToggleable = hasDetails && detailsToggleable;
            const thisItemDetailsVisible =
              detailsVisibleMap[item.arrayKey] || false;

            return (
              <Fragment key={item.arrayKey}>
                <tr
                  className={classNames(styles.Item, item?.className, {
                    [styles.hasDetails]: hasDetails,
                    [styles.isToggleable]: isToggleable,
                  })}
                  onClick={
                    isToggleable
                      ? () => toggleDetailsVisiblity(item.arrayKey)
                      : undefined
                  }
                >
                  <td
                    data-hc-name={`${dataHcName}-item-label`}
                    className={classNames(styles.ItemLabel, {
                      [styles.itemLabelBold]: boldItemLabels,
                      [styles.itemLabelWithExpandIndicator]: isToggleable,
                    })}
                    valign="bottom"
                  >
                    {item.label}{' '}
                    {isToggleable && (
                      <DirectionalChevron
                        smallIcon
                        direction={thisItemDetailsVisible ? 'up' : 'down'}
                      />
                    )}
                  </td>
                  {/* assuming value columns won't change */}
                  {item.columns?.map((c, columnIndex) => (
                    <td
                      key={columnIndex}
                      className={classNames(styles.ItemLabel, {
                        [styles.itemLabelBold]: boldItemLabels,
                      })}
                      valign="bottom"
                      align="right"
                    >
                      {c}
                    </td>
                  ))}

                  <td
                    valign="bottom"
                    data-hc-name={`${dataHcName}-item-value`}
                    className={styles.ItemValue}
                  >
                    {formatter(item.value)}
                  </td>
                </tr>
                {thisItemDetailsVisible &&
                  item.details?.map((itemDetail) => {
                    return (
                      <tr key={itemDetail.arrayKey} className={styles.Detail}>
                        <td className={styles.DetailLabel} valign="bottom">
                          {itemDetail.label}
                        </td>
                        {/* assuming value columns won't change */}
                        {itemDetail.columns?.map((c, columnIndex) => (
                          <td
                            key={columnIndex}
                            className={styles.DetailLabel}
                            align="right"
                            valign="bottom"
                          >
                            {c}
                          </td>
                        ))}
                        <td className={styles.DetailValue} valign="bottom">
                          {formatter(itemDetail.value)}
                        </td>
                      </tr>
                    );
                  })}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <div
        className={classNames(styles.Total, {
          [styles.disabled]: total.disabled,
        })}
      >
        <div
          data-hc-name={`${dataHcName}-total-label`}
          className={styles.TotalLabel}
        >
          {total.label}
        </div>
        <div
          data-hc-name={`${dataHcName}-total-value`}
          className={styles.TotalValue}
        >
          {total.editable ? (
            <input
              className={classNames(styles.TotalInput, styles.TotalValue)}
              value={inputValue || ''}
              data-hc-name={`${dataHcName}-value-input`}
              disabled={total.disabled}
              onFocus={() => {
                if (inputValue !== null) {
                  setInputValue(unformat(inputValue).toString());
                }
              }}
              onChange={(e) => {
                setInputValue(e.target.value === '' ? null : e.target.value);
                total.onChange?.(
                  e.target.value ? unformat(e.target.value) : null
                );
              }}
              onBlur={() => {
                if (inputValue !== null) {
                  setInputValue(formatter(Number(inputValue)));
                }
                total.onBlur?.(inputValue ? unformat(inputValue) : null);
              }}
            />
          ) : (
            formatter(total.value)
          )}
        </div>
      </div>
    </section>
  );
};
