import { Account, AppConfig, OrgType, Roles } from '@hcs/types';

export const isUserAssignedRole = (
  roles: Roles[],
  account: Account | undefined
) => {
  for (const role of roles) {
    if (account?.user.roles.find((r) => r.name === role)) {
      return true;
    }
  }
  return false;
};

export const checkUserIsHcAdmin = (account: Account): boolean => {
  return account.user.roles.some((role) => role.name === 'Administrator');
};

export const checkUserIsOrgAdmin = (account: Account): boolean => {
  return account.user.roles.some((role) => role.name === 'Power User');
};

export const checkUserIsLegacySelfService = (account: Account): boolean => {
  return account.currentOrganization.type === OrgType.LegacySS;
};

export const checkAppAccess = (
  account: Account,
  appConfig: AppConfig | undefined
) => {
  if (!appConfig) return false;
  const { appSlug, requiredRole, feOnlyApp } = appConfig;
  if (requiredRole) {
    return isUserAssignedRole(requiredRole, account);
  }
  if (feOnlyApp) {
    return true;
  }
  const applications = account.applications;
  const userApplications = account.user.userApplications;

  const userApplicationForApp = userApplications.find(
    (app) => app.application.name === appSlug
  );
  // userApplicationForApp is not present when user is in trial mode or is admin for organization
  const hasUserApplicationAccess =
    !userApplicationForApp ||
    userApplicationForApp.state === 'allow' ||
    userApplicationForApp.state === 'grant';
  return applications.includes(appSlug) && hasUserApplicationAccess;
};
