import { useNavigate } from 'react-router';

import { APP_CONFIG_ORG_ADMIN, VIEW_PATHS_ORG_ADMIN } from '@hcs/hc-products';

export const useNavigateToChangePlan = () => {
  const navigate = useNavigate();
  return () =>
    navigate(
      `${APP_CONFIG_ORG_ADMIN.rootPath}/${VIEW_PATHS_ORG_ADMIN.CHANGE_PLAN}`
    );
};
