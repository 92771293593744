import { LoadingPageProps } from '@hcs/design-system';
import { ENV } from '@hcs/webapps';

export const STRIPE_API_KEY =
  ENV === 'prod'
    ? 'pk_live_51HW2jSK9b0p3eKoSZA70TRYVOjMbK9LGF8F9WppromrZrHOxQ0lYZpyOgpoTp4lDOW6p8xgYxF2k6OCi2m1IicRm00S4P4Fb7s'
    : 'pk_test_51HKlL0BZwEeUsQayhejRp2iT3qP3tMlpzKxzBvkDnwYIQXtN5bFXAAUU7Lh9XDmyNG7SFiMyJHJXHOjmbCMMuiDA00HsanVzW4';

export const SELF_SERVICE_SIGN_UP_CONTACT_URL =
  'https://www.housecanary.com/contact?utm_source=sign-up-flow&utm_medium=platform&utm_campaign=sign-up-flow&utm_content=contact-us';

export const SS_CHECKOUT_SESSION_PARAM = 'ss_checkout_session';

export const CHECKOUT_HELP_ERROR: LoadingPageProps['error'] = {
  title: "We're getting, you help...",
  subtitle:
    'Setting up your account is taking longer than expected. You will be contacted via our in-app support in a moment.',
};

export const PLAN_EVENT_DATA_ID = 'plan';
export const CANCELED_SUBSCRIPTION_EVENT_DATA_ID = 'canceled_subscription';
export const DOWNGRADE_PLAN_EVENT_DATA_ID = 'upcoming_downgrade_plan';
