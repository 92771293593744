import { checkUserIsLegacySelfService } from '../utils/appAccess.utils';

import { useAccount } from './useAccount';

export const useUserIsLegacySelfService = () => {
  const accountQuery = useAccount();
  return {
    ...accountQuery,
    data: accountQuery.isSuccess
      ? checkUserIsLegacySelfService(accountQuery.data)
      : undefined,
  };
};
