import { ReactNode } from 'react';

import { HouseCanaryLogomarkFullColor } from '../../../../foundations';
import { DownArrowIcon, ErrorIcon } from '../../../../svgs';
import { LoadingBar } from '../LoadingBar';

import styles from './LoadingPage.module.css';

export interface LoadingPageProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  isLoading?: boolean;
  dataHcEventSection?: string;
  children?: ReactNode;
  supportCallout?: boolean;
  error?: {
    title?: ReactNode;
    subtitle: ReactNode;
    nextAction?: ReactNode;
  };
}
export const LoadingPage = ({
  dataHcEventSection,
  title,
  supportCallout,
  subtitle,
  isLoading,
  children,
  error,
}: LoadingPageProps) => {
  return (
    <div
      data-hc-event-section={dataHcEventSection}
      className={styles.LoadingPage}
    >
      <LoadingBar
        complete={isLoading || !!error}
        dataHcName="loading"
        className={styles.LoadingBar}
      />
      <div className={styles.Content}>
        <div className={styles.Text}>
          <h1 className={styles.Title}>
            {error ? (
              <>
                <ErrorIcon color="error-10" size="xl" />{' '}
                {error.title || 'Something went wrong'}
              </>
            ) : (
              <>
                <HouseCanaryLogomarkFullColor height={40} />{' '}
                {title || 'Just a moment...'}
              </>
            )}
          </h1>
          {(subtitle || error?.subtitle) && (
            <h2 className={styles.SubTitle}>
              {error ? error.subtitle : subtitle}
            </h2>
          )}
          <div className={styles.Redirect}>
            {error ? error.nextAction : children}
          </div>
        </div>
      </div>
      {error && supportCallout && (
        <div className={styles.Support}>
          Need Help? Click Here to Chat with Support{' '}
          <DownArrowIcon className={styles.SupportArrow} />
        </div>
      )}
    </div>
  );
};
