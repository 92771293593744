import React, { useMemo } from 'react';

import { ShoppingCart } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { useActiveState } from '@hcs/hooks';
import { useOrgSelfServeInfo } from '@hcs/self-serve-info';
import { ItemObjInShoppingCart } from '@hcs/types';
import { DexpEndpoint, DexpEndpointsParams } from '@hcs/types';

import { DexpTemplatesMenuForUser } from '../../features/DexpTemplatesMenuForUser';
import { SaveAnalysisTemplateLauncher } from '../../features/SaveAnalysisTemplate/SaveAnalysisTemplateLauncher';
import { useDexpEndpointsTable } from '../../hooks/useDexpEndpointsTable';
import { DexpAnalysisPriceConfirmationDialog } from '../DexpAnalysisPriceEstimate';

import styles from './DexpShoppingCartCard.module.css';

export interface DexpShoppingCartCardProps {
  onClickCta: (
    endpointsInShoppingCart: Record<DexpEndpoint['id'], ItemObjInShoppingCart>
  ) => void;
  ctaLabel: string;
  filters?: DexpEndpointsParams;
}

const dataHcName = 'selected-endpoint-card';

export const DexpShoppingCartCard = ({
  onClickCta,
  ctaLabel,
  filters,
}: DexpShoppingCartCardProps) => {
  const {
    state: {
      selectedLength,
      dexpEndpointsQuery,
      endpointsInShoppingCart,
      selectedDexpTemplate,
    },
    actions: {
      addToCart,
      clearCart,
      deselectAndLeaveInCart,
      removeFromCart,
      setEndpointsInCart,
    },
  } = useDexpEndpointsTable(filters);
  const { active, handleOpen, handleClose } = useActiveState();
  const { data: orgSsInfo } = useOrgSelfServeInfo();
  const {
    isInitialLoading: isDexpEndpointsQueryLoading,
    data: dexpEndpointsQueryData,
  } = dexpEndpointsQuery;

  const itemKeysInShoppingCart = Object.keys(endpointsInShoppingCart);
  const headerText = `${selectedLength} data point${
    selectedLength > 1 ? 's' : ''
  } selected`;

  const selectedEndpoints = useMemo(() => {
    const endpointsData: Record<DexpEndpoint['id'], ItemObjInShoppingCart> =
      endpointsInShoppingCart;
    return endpointsData;
  }, [endpointsInShoppingCart]);

  const handleOnSelectItem = (endpointId: DexpEndpoint['id']) => {
    if (dexpEndpointsQueryData) {
      const selectedEndpoint = dexpEndpointsQueryData.filter(
        (endpoint) => endpoint.id === endpointId
      )[0];
      const name = selectedEndpoint?.name;
      const level = selectedEndpoint?.level;

      if (name && level) addToCart(endpointId, name, level);
    }
  };

  if (
    !itemKeysInShoppingCart ||
    itemKeysInShoppingCart.length === 0 ||
    isDexpEndpointsQueryLoading
  ) {
    return (
      <ExperienceFlag
        experienceFlagId="DEXP_TEMPLATES"
        next={
          <div>
            <DexpTemplatesMenuForUser
              className={styles.TemplatesSelector}
              onSelect={(template) => {
                setEndpointsInCart({ endpointsData: template.endpointsData });
              }}
            />
          </div>
        }
      />
    );
  }

  return (
    <>
      <ShoppingCart
        className={styles.ShoppingCartCard}
        dataHcName="selected-endpoint-card"
        itemsInShoppingCart={endpointsInShoppingCart}
        footerCtaText={ctaLabel}
        footerCtaDataHcName={`${dataHcName}-upload-csv-button`}
        headerText={headerText}
        onClearCart={clearCart}
        onClickFooterCta={
          orgSsInfo ? handleOpen : () => onClickCta(endpointsInShoppingCart)
        }
        onSelectItem={handleOnSelectItem}
        onDeselectAndLeaveInCart={deselectAndLeaveInCart}
        onRemoveFromCart={removeFromCart}
        rightSideHeader={
          <ExperienceFlag
            experienceFlagId="DEXP_TEMPLATES"
            next={
              <SaveAnalysisTemplateLauncher
                endpointsData={selectedEndpoints}
                templateId={selectedDexpTemplate?.templateId}
              />
            }
          />
        }
        selectedLength={selectedLength}
        removeFromCartCtaText={''}
      />
      <DexpAnalysisPriceConfirmationDialog
        active={active}
        onClose={handleClose}
        onClickCta={onClickCta}
        ctaLabel="Continue to Upload"
      />
    </>
  );
};
