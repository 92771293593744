import { useAppConfigExtended, useUserIsLegacySelfService } from '@hcs/auth';
import {
  APP_CONFIG_MARKET_INSIGHTS,
  ProductPreview,
  ProductPreviewDialog,
  useRequestProductAccess,
} from '@hcs/hc-products';
import { useActiveState } from '@hcs/hooks';
import { formatForDataHcName } from '@hcs/utils';

export const usePremiumMarketInsightsFeatures = () => {
  const dataHcName = `product-preview${formatForDataHcName(
    APP_CONFIG_MARKET_INSIGHTS.appSlug
  )}`;
  const marketInsightsConfigExtended = useAppConfigExtended(
    APP_CONFIG_MARKET_INSIGHTS.appSlug
  );
  const {
    data: userIsLegacySelfService,
    isInitialLoading: userIsSelfServiceIsLoading,
  } = useUserIsLegacySelfService();
  const {
    active: marketInsightsActive,
    handleOpen: handleMarketInsightsOpen,
    handleClose: handleMarketInsightsClose,
  } = useActiveState();
  const requestProductQueryMutation = useRequestProductAccess();
  const isFreeMiUser = userIsLegacySelfService || userIsSelfServiceIsLoading;

  const renderedProductPreviewDialog = (
    <ProductPreviewDialog
      premiumFeature
      limitedAccess={isFreeMiUser}
      appConfigExtended={marketInsightsConfigExtended}
      active={marketInsightsActive}
      onClose={handleMarketInsightsClose}
    />
  );

  const renderedProductPreview = marketInsightsConfigExtended ? (
    <ProductPreview
      premiumFeature
      limitedAccess={isFreeMiUser}
      dataHcName={dataHcName}
      appConfigExtended={marketInsightsConfigExtended}
      onClickRequestAccess={() =>
        requestProductQueryMutation.mutate({
          requestedProduct: APP_CONFIG_MARKET_INSIGHTS.appSlug,
        })
      }
    />
  ) : null;

  return {
    handleMarketInsightsOpen,
    handleMarketInsightsClose,
    isFreeMiUser,
    renderedProductPreviewDialog,
    renderedProductPreview,
  };
};
