import { MutableRefObject, useCallback } from 'react';
import {
  EventStreamContentType,
  FetchEventSourceInit,
} from '@microsoft/fetch-event-source';
import { useQueryClient } from '@tanstack/react-query';

import { hcsConsole } from '@hcs/console';
import {
  ConnectionStatus,
  EventSourceType,
  ReportId,
  SSEEvent,
  SSEEventTypes,
} from '@hcs/types';
import { capitalizeFirstLetter } from '@hcs/utils';

class RetriableError extends Error {}
class FatalError extends Error {}
class TokenExpiredError extends Error {}

type EventSourceInput = {
  id: string | ReportId;
  config: FetchEventSourceInit;
};

const NETWORK_ERROR_NAMES_AND_MESSAGES = [
  'AbortError',
  'Network Error',
  'network error',
  'Failed to fetch',
  'TypeError: Load failed',
  'LoadFailed',
  'Error in input stream',
  'NetworkError when attempting to fetch resource.',
];

export const useCreateEventSource = <T extends SSEEventTypes>() => {
  const queryClient = useQueryClient();

  return useCallback(
    (
      type: EventSourceType,
      id: string | ReportId,
      apiFunction: (eventSourceInput: EventSourceInput) => Promise<void>,
      eventSource: MutableRefObject<{
        eventSourceAbortController: AbortController;
        id: string | ReportId;
      } | null>,
      connectionStatus: MutableRefObject<ConnectionStatus>,
      listeners: MutableRefObject<{
        onMessage: Map<string, (e: SSEEvent<T>) => void | undefined>;
      }>
    ) => {
      const ctrl = new AbortController();
      if (eventSource) {
        eventSource.current = {
          eventSourceAbortController: ctrl,
          id,
        };
      }

      return apiFunction({
        id,
        config: {
          signal: ctrl.signal,
          openWhenHidden: false,
          async onopen(response) {
            if (
              response.ok &&
              response.headers.get('content-type') === EventStreamContentType
            ) {
              hcsConsole.log(
                `${capitalizeFirstLetter(
                  type
                )}-Api: SSE | connected, ${capitalizeFirstLetter(type)} = ${id}`
              );
              connectionStatus.current = ConnectionStatus.Connected;
              return;
            } else if (response.status === 401) {
              throw new TokenExpiredError();
            } else if (
              response.status >= 402 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              hcsConsole.logVerbose(
                `${capitalizeFirstLetter(type)}-Api: SSE |` as never,
                `onOpen response status: ${response.status}, fatal error` as never
              );
              throw new FatalError();
            } else {
              throw new RetriableError();
            }
          },
          onclose() {
            hcsConsole.logVerbose(
              `${capitalizeFirstLetter(type)}-Api: SSE |`,
              `server closed connection unexpectedly, retrying`
            );

            throw new RetriableError();
          },
          onmessage(msg) {
            if (msg.event === 'FatalError') {
              throw new FatalError(msg.data);
            } else if (msg.event === 'ping') {
              return;
            } else {
              const event = JSON.parse(msg.data) as SSEEvent<T>;
              hcsConsole.logVerbose(
                `${capitalizeFirstLetter(type)}-Api: SSE |`,
                event
              );

              listeners.current.onMessage.forEach((callback) => {
                callback(event);
              });
            }
          },
          onerror(err) {
            if (err instanceof FatalError || err instanceof TokenExpiredError) {
              connectionStatus.current = ConnectionStatus.Disconnected;
              throw err;
            } else if (err instanceof RetriableError) {
              connectionStatus.current = ConnectionStatus.Retrying;
              return 3000;
            } else {
              connectionStatus.current = ConnectionStatus.Retrying;

              if (
                !NETWORK_ERROR_NAMES_AND_MESSAGES.includes(err?.name) &&
                !NETWORK_ERROR_NAMES_AND_MESSAGES.includes(err?.message)
              ) {
                hcsConsole.log(
                  `${capitalizeFirstLetter(
                    type
                  )}ApiEvents.context.tsx: retrying after unexpected error: ${err}`
                );
              }

              return 10000;
            }
          },
        },
      });
    },
    [queryClient]
  );
};
