import { useMemo } from 'react';

import { Anchor, Card, Menu } from '@hcs/design-system';
import { useExternalAppConfigsByKey } from '@hcs/hc-products';
import { CapabilityApplications } from '@hcs/types';

import styles from './GrafanaDashboards.module.css';
const DASHBOARDS = [
  {
    applicationKey: CapabilityApplications.AcquisitionExplorer,
    category: null,
    name: 'Lead Feed Delivery Overview',
    link: 'https://grafana.prod.infra.housecanary.net/d/CmAVltXWz/lead-feed-overview-prod?orgId=1',
  },
  {
    applicationKey: CapabilityApplications.AcquisitionExplorer,
    category: null,
    name: 'Lead Feed Recent Deliveries',
    link: 'https://grafana.prod.infra.housecanary.net/d/WPoFLgQWk/lead-feed-recent-deliveries-prod?orgId=1&var-org_name=pretium',
  },
  {
    applicationKey: CapabilityApplications.AcquisitionExplorer,
    category: null,
    name: 'Other Lead Feed Dashboards',
    link: 'https://grafana.prod.infra.housecanary.net/dashboards/f/VtV8qaoGk/lead-feed-prod?',
  },
  {
    applicationKey: CapabilityApplications.AgileSuite,
    category: null,
    name: 'Overview',
    link: 'https://grafana.prod.infra.housecanary.net/d/DFxfPFbMk/order-manager-overview?orgId=1',
  },
  {
    applicationKey: CapabilityApplications.AgileSuite,
    category: null,
    name: 'Order Manager API',
    link: 'https://grafana.prod.infra.housecanary.net/d/rA0BmKxGz/order-manager-api?orgId=1',
  },
  {
    applicationKey: CapabilityApplications.AgileSuite,
    category: null,
    name: 'Customer Order Summary',
    link: 'https://grafana.prod.infra.housecanary.net/d/4RkkNAanz/order-manager-customer-order-summary?orgId=1',
  },
  {
    applicationKey: CapabilityApplications.AgileSuite,
    category: null,
    name: 'Fannie Mae Orders',
    link: 'https://grafana.prod.infra.housecanary.net/d/Jr4ab0dVz/order-manager-fnma-orders?orgId=1',
  },
  {
    name: 'Account Images',
    applicationKey: null,
    category: 'Vulnerabilities',
    link: 'https://grafana.prod.infra.housecanary.net/d/vuln-account-image/account-image-vulnerabilities?orgId=1&var-accountNumber=238370184623&var-jobName=vulnscan-prod',
  },
  {
    name: 'Twistlock',
    applicationKey: null,
    category: 'Vulnerabilities',
    link: 'https://grafana.prod.infra.housecanary.net/d/BySKCX5Gz/twistlock?from=now-3h&orgId=1&to=now&var-account_env=prod&var-account_name=hc',
  },
  {
    applicationKey: null,
    category: 'ComeHome',
    name: 'Consumer-API Metrics',
    link: 'https://g-1913239265.grafana-workspace.us-west-2.amazonaws.com/d/ddyw0pm4ui3gga/consumer-api-metrics?orgId=1',
  },
  {
    applicationKey: null,
    category: 'ComeHome',
    name: 'ComeHome Standup',
    link: 'https://g-1913239265.grafana-workspace.us-west-2.amazonaws.com/d/cdyahlr7lksu8a/come-home-standup?orgId=1&from=1726835562508&to=1726857162508',
  },
  {
    applicationKey: null,
    category: 'Platform',
    name: 'Cerberus',
    link: 'https://g-1913239265.grafana-workspace.us-west-2.amazonaws.com/d/cdyahlr7lksu8a/come-home-standup?orgId=1&from=1726835562508&to=1726857162508',
  },
  {
    applicationKey: null,
    category: 'Platform',
    name: 'Usage',
    link: 'https://grafana.prod.infra.housecanary.net/d/fdz6q63xixxxcd/usage?from=now-1h&to=now&timezone=browser',
  },
];

export const GrafanaDashboard = ({
  applicationKey,
  category,
}: {
  applicationKey?: CapabilityApplications;
  category?: string;
}) => {
  const appConfigs = useExternalAppConfigsByKey();
  const appConfig = applicationKey && appConfigs[applicationKey];
  const menuItems = useMemo(() => {
    return DASHBOARDS.filter(
      (dashboard) =>
        dashboard.applicationKey === applicationKey ||
        dashboard.category === category
    ).map((dashboard) => {
      return {
        label: (
          <Anchor
            dataHcName="dashboard-link"
            href={dashboard.link}
            target="_blank"
          >
            {dashboard.name}
          </Anchor>
        ),
        dataHcName: 'dashboard',
      };
    });
  }, [applicationKey, category]);
  return (
    <Card
      key={`dashboards-${applicationKey}`}
      dataHcName={`dashboards-${applicationKey}`}
      title={appConfig?.name || category}
    >
      <Menu menuItems={menuItems} />
    </Card>
  );
};

export const GrafanaDashboards = () => {
  return (
    <div className={styles.Dashboards}>
      <GrafanaDashboard category="Platform" />
      <GrafanaDashboard
        applicationKey={CapabilityApplications.AcquisitionExplorer}
      />
      <GrafanaDashboard applicationKey={CapabilityApplications.AgileSuite} />
      <GrafanaDashboard category="ComeHome" />
      <GrafanaDashboard category="Vulnerabilities" />
    </div>
  );
};
