import { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import FileDownload from 'js-file-download';
import snakecaseKeys from 'snakecase-keys';

import {
  AxiosAccessTokenClientBearer,
  AxiosAccessTokenClientEhrm,
} from '@hcs/http-clients';
import {
  BillingapiBillJobResponseInt,
  BillingapiLatestBillResp,
  BillingapiRecalculateBillsRequestInt,
  OrganizationapiSubmitUsageQueryRequest,
  OrganizationapiSubmitUsageQueryResponse,
  OrganizationapiUsageQueryListResponse,
  OrgUsageQueryOptions,
  Usage,
  UsageQueryListParams,
  ZuoraInvoice,
  ZuoraInvoicesInput,
  ZuoraOrderDirectionTypes,
  ZuoraRequestParams,
} from '@hcs/types';
import {
  ACCOUNT_URL,
  ACCOUNT_URL_INTERNAL,
  EHRMANTRAUT_ADMIN_INTERNAL_URL,
  EHRMANTRAUT_URL,
} from '@hcs/urls';
import {
  dateStrToFormattedDate,
  formatDateShort,
  getOffsetFromPaginationOptions,
  logException,
} from '@hcs/utils';
import { makePaginatedResponseData } from '@hcs/utils';

const DEFAULT_ZUORA_START_PAGE = 1;
export const DEFAULT_ZUORA_ITEMS_PER_PAGE = 50;

const fetchUsageForUser = async () => {
  const response = await AxiosAccessTokenClientEhrm.get<Usage[]>(
    `${EHRMANTRAUT_URL}/usage/my-usage`
  );
  return camelcaseKeys(response.data, { deep: true }) as Usage[];
};

const fetchUsageForOrg = async (orgId?: number) => {
  const response = await AxiosAccessTokenClientEhrm.get<Usage[]>(
    `${EHRMANTRAUT_URL}/usage/current`,
    {
      params: orgId ? { organization_id: orgId } : undefined,
    }
  );
  return camelcaseKeys(response.data, { deep: true }) as Usage[];
};

const fetchZuoraInvoices = async ({
  status,
  orderBy,
  orderDirection,
  page,
  pageSize,
}: ZuoraInvoicesInput) => {
  const params: ZuoraRequestParams = {
    includeItems: 1,
    page: page ? page : DEFAULT_ZUORA_START_PAGE,
    perPage: pageSize ? pageSize : DEFAULT_ZUORA_ITEMS_PER_PAGE,
  };

  if (status) {
    params.status = status;
  }

  if (orderBy) {
    params.orderBy = orderBy;
    params.orderDirection = orderDirection
      ? orderDirection
      : ZuoraOrderDirectionTypes.desc;
  }
  const response = await AxiosAccessTokenClientEhrm.get<ZuoraInvoice[]>(
    `${EHRMANTRAUT_URL}/zuora-api/invoice`,
    {
      params: snakecaseKeys(params),
    }
  );

  return makePaginatedResponseData(
    response,
    {
      page: page || DEFAULT_ZUORA_START_PAGE,
      pageSize: pageSize || DEFAULT_ZUORA_ITEMS_PER_PAGE,
    },
    { camelCaseKeys: true }
  );
};

const downloadZuoraInvoicePdf = async (invoice: ZuoraInvoice) => {
  const response = await AxiosAccessTokenClientEhrm.get<ArrayBuffer>(
    `${EHRMANTRAUT_URL}/zuora-api/invoice/${invoice.id}/pdf`,
    { responseType: 'arraybuffer' }
  );
  FileDownload(
    new window.Blob([response.data], { type: 'application/pdf' }),
    `HCS Invoice | ${formatDateShort(
      invoice.items[0]?.serviceStartDate
    )} - ${formatDateShort(invoice.items[0]?.serviceEndDate)}.pdf`
  );
};

const recalculateBills = async (
  request: BillingapiRecalculateBillsRequestInt
) => {
  const response =
    await AxiosAccessTokenClientBearer.post<BillingapiBillJobResponseInt>(
      `${ACCOUNT_URL_INTERNAL}/billing/mapi/bills/recalculate`,
      request
    );
  return response.data;
};

const getBillJob = async (
  billJobId: NonNullable<BillingapiBillJobResponseInt['id']>
) => {
  const response =
    await AxiosAccessTokenClientBearer.get<BillingapiBillJobResponseInt>(
      `${ACCOUNT_URL_INTERNAL}/billing/mapi/bills/job/${billJobId}`
    );
  return response.data;
};

const createOrgUsageQuery = async (
  orgId: number,
  usageQueryData: OrganizationapiSubmitUsageQueryRequest
) => {
  const requestData = { ...usageQueryData };
  // the form picks dates but the backend expects full iso datetime strings
  requestData.startReqTime = `${usageQueryData.startReqTime}T00:00:00Z`;
  requestData.endReqTime = `${usageQueryData.endReqTime}T00:00:00Z`;
  const response = await AxiosAccessTokenClientBearer.post<
    OrganizationapiSubmitUsageQueryResponse,
    AxiosResponse<OrganizationapiSubmitUsageQueryResponse>,
    OrganizationapiSubmitUsageQueryRequest
  >(`${ACCOUNT_URL}/organizations/${orgId}/usage-query`, requestData);
  return response.data;
};

const getOrgUsageQueryList = async (
  orgUsageQueryOptions: OrgUsageQueryOptions
) => {
  const searchParams: UsageQueryListParams = {
    limit: orgUsageQueryOptions.pagination.pageSize,
    offset: getOffsetFromPaginationOptions(orgUsageQueryOptions.pagination),
    queryType: orgUsageQueryOptions.filters.queryType,
    orderBy: orgUsageQueryOptions.pagination.orderBy,
  };
  const creatorEmailFiler = orgUsageQueryOptions.filters.creatorEmail;
  if (creatorEmailFiler) {
    searchParams.creatorEmail = creatorEmailFiler;
  }
  const response = await AxiosAccessTokenClientBearer.get<
    OrganizationapiUsageQueryListResponse[]
  >(
    `${ACCOUNT_URL}/organizations/${orgUsageQueryOptions.filters?.orgId}/usage-query`,
    {
      params: searchParams,
    }
  );
  // convert iso datetime strings to human readable dates
  // (since these are UTC datetimes, we don't want to convert to dates and deal with timezone offsets)
  response.data.forEach((query) => {
    const startDatetime = query.startDatetime;
    const formattedStartdatetime = startDatetime
      ? dateStrToFormattedDate(startDatetime.split('T')[0])
      : startDatetime;
    query.startDatetime = formattedStartdatetime || undefined;
    const endDatetime = query.endDatetime;
    const formattedEnddatetime = endDatetime
      ? dateStrToFormattedDate(endDatetime.split('T')[0])
      : endDatetime;
    query.endDatetime = formattedEnddatetime || undefined;
  });

  return makePaginatedResponseData(response, orgUsageQueryOptions.pagination, {
    noPaginationLinks: true,
  });
};

const downloadOrgUsageQueryResult = async (
  query: OrganizationapiUsageQueryListResponse
) => {
  if (
    query.fileName !== undefined &&
    query.id !== undefined &&
    query.organizationID !== undefined
  ) {
    const response = await AxiosAccessTokenClientBearer.get<Blob>(
      `${ACCOUNT_URL}/organizations/${query.organizationID}/usage-query/${query.id}/download`,
      { responseType: 'blob' }
    );
    FileDownload(response.data, query.fileName);
  } else {
    logException('downloadUsageQueryResult: Missing required fields in query');
    throw new Error('Error occurred while downloading the file');
  }
};

const downloadOrgUsageZuoraQueryResult = async (
  query: OrganizationapiUsageQueryListResponse
) => {
  if (
    query.fileName !== undefined &&
    query.id !== undefined &&
    query.organizationID !== undefined
  ) {
    const response = await AxiosAccessTokenClientEhrm.get<Blob>(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/usage/organization/${query.organizationID}/usage-query/${query.id}/download`,
      { responseType: 'blob' }
    );
    FileDownload(response.data, query.fileName);
  } else {
    logException('downloadUsageQueryResult: Missing required fields in query');
    throw new Error('Error occurred while downloading the file');
  }
};

export const BillingApi = {
  fetchUsageForUser,
  fetchUsageForOrg,
  fetchZuoraInvoices,
  downloadZuoraInvoicePdf,
  fetchLatestBill: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<BillingapiLatestBillResp>(
        // TODO: remove /mapi after eugene's pr is merged
        ACCOUNT_URL === ACCOUNT_URL_INTERNAL
          ? `${ACCOUNT_URL_INTERNAL}/billing/mapi/${orgId}/bills/latest`
          : `${ACCOUNT_URL}/billing/${orgId}/bills/latest`
      );
    return response.data;
  },
  recalculateBills,
  getBillJob,
  createOrgUsageQuery,
  getOrgUsageQueryList,
  downloadOrgUsageQueryResult,
  downloadOrgUsageZuoraQueryResult,
};
