import { EngineeringToolsIcon } from '@hcs/design-system';
import { AppSlugs, CapabilityApplications, Roles } from '@hcs/types';
import { AppConfig } from '@hcs/types';

export const APP_CONFIG_ENG_TOOLS: AppConfig = {
  name: 'Engineering Tools',
  appSlug: AppSlugs.EngTools,
  applicationKey: CapabilityApplications.InternalEngTools,
  Icon: EngineeringToolsIcon,
  rootPath: '/eng-tools',
  descriptionLong: 'Tools for the HC Product Engineering Team',
  descriptionShort: 'Tools for the HC Product Engineering Team',
  feOnlyApp: true,
  requiredRole: [Roles.EngTools],
};

export enum VIEW_PATHS_ENG_TOOLS {
  GRAFANA_DASHBOARDS = 'grafana-dashboards',
  LOGS = 'logs',
  IMAGE_REVIEW = 'image-review',
  IMAGE_VIEWER = 'image-viewer',
  REPORT_REPAIR = 'report-repair',
}
