import { SHA256 } from 'crypto-js';
import { getCookie } from 'typescript-cookie';

import { getPlanSubscriptionType } from '@hcs/self-serve-info';
import { SelfServePlan } from '@hcs/types';
import { formatList, logException } from '@hcs/utils';
import { ENV } from '@hcs/webapps';

interface DataLayerObject {
  event?: string;
  [key: string]: unknown;
}
declare global {
  interface Window {
    dataLayer: DataLayerObject[];
  }
}

const getUidCookie = () => getCookie('hcid');
const getEventTimestamp = () => new Date().toISOString();
const getEcommerceData = (plan: SelfServePlan) => {
  const planSubscriptionType = getPlanSubscriptionType(plan);
  return {
    currency: 'USD',
    value: plan.price, // Set value to the sum of (price * quantity) for all items in items. Don't include tax.
    items: [
      {
        item_id: planSubscriptionType,
        item_name: planSubscriptionType,
        price: plan.price, // price of the plan selected
        quantity: 1, // Set as 1 since one subscription is purchased at a time.
        payment_frequency: plan.term.toLowerCase(),
      },
    ],
  };
};

function fireAddToCart(plan: SelfServePlan) {
  const e = {
    event: 'add_to_cart',
    hc_uid: getUidCookie(), // ID of cookie set when user lands on solutions-subdomain
    event_timestamp: getEventTimestamp(),
    ecommerce: getEcommerceData(plan),
  };
  if (ENV === 'prod') {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(e);
  } else {
    console.log('[GTM Tracking] add_to_cart', e);
  }
}

function fireSignUp(email: string, plan: SelfServePlan) {
  if (!plan) {
    logException('sign_up GTM event fired without a plan');
  }
  const e = {
    event: 'sign_up',
    hc_uid: getUidCookie(),
    user_hashed_email: SHA256(email).toString(),
    event_timestamp: getEventTimestamp(),
    ecommerce: getEcommerceData(plan),
  };
  if (ENV === 'prod') {
    window.dataLayer.push(e);
  } else {
    console.log('[GTM Tracking] sign_up', e);
  }
}

function firePurchase(
  email: string | undefined,
  plan: SelfServePlan | undefined
) {
  if (!plan || !email) {
    // This should never happen but we will log a sentry exception if it does.
    const missingFields = [];
    if (!plan) {
      missingFields.push('plan');
    }
    if (!email) {
      missingFields.push('email');
    }
    logException(
      `purchase GTM event fired without required fields: ${formatList(
        missingFields
      )}`
    );
  }
  const e = {
    event: 'purchase',
    hc_uid: getUidCookie(),
    user_hashed_email: email ? SHA256(email).toString() : '',
    event_timestamp: getEventTimestamp(),
    ecommerce: plan ? getEcommerceData(plan) : undefined,
  };
  if (ENV === 'prod') {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(e);
  } else {
    console.log('[GTM Tracking] purchase', e);
  }
}

export const useGoogleTagManagerEvents = () => {
  return {
    fireAddToCart,
    fireSignUp,
    firePurchase,
  };
};
