import { PortfolioEventTypes } from '../portfolio';
import { ReportEventTypes } from '../report-api';

export enum EventSourceType {
  Portfolio = 'portfolio',
  Report = 'report',
}

export enum ConnectionStatus {
  Disconnected = 'disconnected',
  Connected = 'connected',
  Retrying = 'retrying',
}

export interface SSEEvent<T> {
  sseEventType: EventSourceType;
  message: string;
  type: T;
  patchStatus?: 'IN_PROGRESS' | 'DONE' | 'ERROR';
  percentComplete?: number;
}

export type SSEEventTypes = ReportEventTypes | PortfolioEventTypes;
