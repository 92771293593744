import { MutableRefObject, useCallback } from 'react';

import { SSEEvent, SSEEventTypes } from '@hcs/types';

export const useSubscribeToServerSentEvents = <T extends SSEEventTypes>(
  componentsListening: MutableRefObject<Map<string, Map<string, boolean>>>,
  listeners: MutableRefObject<{
    onMessage: Map<string, (e: SSEEvent<T>) => void | undefined>;
  }>
) => {
  return useCallback(
    ({
      callbackId,
      componentId,
      onMessage,
    }: {
      callbackId: string;
      componentId: string;
      onMessage: (e: SSEEvent<T>) => void;
    }) => {
      if (!componentsListening.current.has(callbackId)) {
        componentsListening.current.set(callbackId, new Map<string, boolean>());
      }

      const componentsListeningToCallbackId =
        componentsListening.current.get(callbackId);
      componentsListeningToCallbackId?.set(componentId, true);

      const existingListener = listeners.current.onMessage?.get(callbackId);
      if (onMessage !== existingListener) {
        listeners.current.onMessage.set(callbackId, onMessage);
      }
    },
    [componentsListening, listeners]
  );
};
