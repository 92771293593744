import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useDynamicRefs } from '@hcs/hooks';

import styles from './Toggle.module.css';

interface ToggleTheme {
  Toggle?: string;
  Option?: string;
  SelectedMask?: string;
  selected?: string;
  disabled?: string;
  disabledOption?: string;
}

export interface ToggleOption<T> {
  label: string | number | ReactNode;
  value: T;
  disabled?: boolean;
  /** Selector for automated testing. */
  dataHcName?: string;
}

export interface ToggleProps<T> {
  /** Options for the toggle. Format: { label: <string>, value: <any> }  */
  options: Array<ToggleOption<T>>;
  /** Callback executed on select, with the selected option's value as the argument */
  onChange: (value: T) => void;
  /** Whether the control is disabled */
  disabled?: boolean;
  /** The value that should be shown as selected */
  value: T;
  /** Selector for automated testing */
  dataHcName: string;
  /** Class Name */
  className?: string;
  style?: React.CSSProperties;
  theme?: ToggleTheme;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  equalWidthOptions?: boolean;
}

export const Toggle = <T extends string | number | null | boolean>({
  className,
  value,
  options,
  onChange,
  disabled,
  dataHcName,
  style,
  theme,
  primary = true,
  secondary = false,
  tertiary = false,
  equalWidthOptions = true,
}: ToggleProps<T>) => {
  const [optionWidths, setOptionWidths] = useState<number[]>(
    options.map(() => 0)
  );
  const [setRef, getRef] = useDynamicRefs<HTMLDivElement>();
  const calcWidth = useCallback(
    (options: ToggleProps<T>['options'], equalWidthOptions: boolean) => {
      let largestOptionsWidth = 0;
      const optionWidthsTmp: number[] = [];
      options.forEach((option, i) => {
        const ref = getRef(`option-${i}`);
        if (ref?.current) {
          const optionWidth = ref.current.offsetWidth;
          largestOptionsWidth = Math.max(optionWidth, largestOptionsWidth);
          optionWidthsTmp.push(optionWidth);
        }
      });
      if (equalWidthOptions) {
        setOptionWidths(options.map(() => largestOptionsWidth));
      } else {
        setOptionWidths(optionWidthsTmp);
      }
    },
    [getRef]
  );
  useEffect(() => {
    calcWidth(options, equalWidthOptions);
  }, [options, calcWidth, equalWidthOptions]);
  const selectedIndex = options.findIndex((option) => option.value === value);
  const selectedIndexWidth = optionWidths[selectedIndex];
  // want to add up widths of all options to the left of the selected option
  const maskLeftOffset = optionWidths
    .slice(0, selectedIndex)
    .reduce((a, b) => a + b, 0);

  const isPrimaryStyle = primary && !secondary && !tertiary;

  return (
    <div
      style={{
        ...style,
        visibility: optionWidths[0] ? undefined : 'hidden',
      }}
      className={classNames(styles.Toggle, theme?.Toggle, className, {
        [styles.disabled]: disabled,
        [styles.Primary]: isPrimaryStyle,
        [styles.Secondary]: !isPrimaryStyle && secondary,
        [styles.Tertiary]: !isPrimaryStyle && tertiary,
      })}
      data-hc-name={dataHcName}
      role="listbox"
    >
      <div className={styles.OptionsContainer}>
        {options.map((option, i) => {
          const isSelected = selectedIndex === i;
          const optionWidth = optionWidths[i];
          return (
            <div
              key={`toggle-${option.value}`}
              ref={setRef(`option-${i}`)}
              style={optionWidth ? { width: `${optionWidth}px` } : undefined}
              className={classNames(styles.Option, theme?.Option, {
                [styles.disabledOption]: option.disabled,
                [theme?.disabledOption || '']: option.disabled,
                [styles.selected]: isSelected,
                [theme?.selected || '']: isSelected,
              })}
              data-hc-name={option.dataHcName || `${dataHcName}-option-${i}`}
              onClick={() => {
                if (!disabled && !option.disabled) {
                  onChange(option.value);
                }
              }}
              role="option"
              aria-selected={isSelected}
            >
              {option.label || option.value}
            </div>
          );
        })}
      </div>
      <div
        className={classNames(styles.SelectedMask, theme?.SelectedMask)}
        style={{
          width: `${selectedIndexWidth}px`,
          left: `${maskLeftOffset}px`,
        }}
      />
      <input
        readOnly
        type="hidden"
        value={`${dataHcName}-option-${selectedIndex}`}
        data-hc-name={`${dataHcName}-value`}
      />
    </div>
  );
};
