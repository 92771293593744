import {
  OranizationSubscriptionType,
  Organization,
  OrgType,
  SelfServePlan,
  SelfServeSubscriptionTypes,
} from '@hcs/types';

export const getPlanSubscriptionType = (
  plan: SelfServePlan
): SelfServeSubscriptionTypes => `${plan.name}-${plan.term}`;

export const getOrgSubscriptionType = (
  type: Organization['type'] | undefined,
  plan?: SelfServePlan
): OranizationSubscriptionType | undefined =>
  plan
    ? getPlanSubscriptionType(plan)
    : type === OrgType.LegacySS
    ? 'self-service'
    : type;
